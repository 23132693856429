import EcoCartAdminDashboard from '@components/dashboards/Dashboard.EcoCartAdmin';
import MerchantAdminDashboard from '@components/dashboards/Dashboard.MerchantAdmin';
import EcoScrollView from '@components/views/EcoScrollView';
import { useGlobal } from '@hooks/useGlobal';
import { GlobalScreenProps } from '@navigation/types';
import React from 'react';

export default function HomeScreen({}: GlobalScreenProps): JSX.Element {
  const { session } = useGlobal();

  if (!session) return <></>;
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';

  if (isEcoCartAdmin)
    return (
      <EcoScrollView>
        <EcoCartAdminDashboard user={session.user} />
      </EcoScrollView>
    );

  return <MerchantAdminDashboard />;
}
