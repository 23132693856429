import { EcoLoadingScreen } from '@components/shared';
import { pascalToSpaced } from '@ecocart/universal-utils';
import { useFeaturePermission } from '@hooks/useFeaturePermission';
import { DefaultTheme, LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { isNative } from '@utils/constants/device';
import { storage, StorageKey } from '@utils/storage';
import React, { useEffect, useMemo, useState } from 'react';
import { Linking } from 'react-native';
import { ProductFruits } from 'react-product-fruits';
import { useGlobal } from '../hooks/useGlobal';
import { useWindow } from '../hooks/useWindow';
import { LinkingConfiguration, PUBLIC_ROUTES } from './LinkingConfiguration';
import { cardStyle, CustomHeader } from './navigation.utils';
import { navigationRef } from './RootNavigation';
import { generateNavigator, NavigatorType, PublicStackNavigator, TabScreenName } from './StackNavigators';

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent'
  }
};

interface RoutesProps {}
interface State {
  key: string;
  index: number;
  routeNames: string[];
  history?: unknown[] | undefined;
  routes: any[];
  type: string;
  stale: false;
}

const getSavedState = async (): Promise<State> => {
  const savedStateJson = await storage.getItem(StorageKey.nav_state);
  return savedStateJson ? JSON.parse(savedStateJson) : undefined;
};

export const AppNavigator: React.FC<RoutesProps> = ({}) => {
  const { isDesktopWeb } = useWindow();
  const { entitlements, session } = useGlobal();
  const [initialState, setInitialState] = useState<State>();
  const [stateIsReady, setStateIsReady] = useState<boolean>(false);
  const sessionIsReady = session !== null;
  const isLoggedIn = !!session;
  const navigatorType: NavigatorType = isDesktopWeb ? 'drawer' : 'tab';
  const createStack = createStackNavigator();
  const Navigator = createStack.Navigator;
  const Group = createStack.Group;
  const Screen = createStack.Screen;

  useEffect(() => {
    if (PUBLIC_ROUTES.includes(location?.pathname as any) || !sessionIsReady || isLoggedIn) return;

    storage.setItem(StorageKey.login_redirect, location.href);
  }, [sessionIsReady, isLoggedIn]);

  useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (isNative && initialUrl == null) {
          const state = await getSavedState();
          if (state) setInitialState(state);
        }
      } finally {
        setStateIsReady(true);
      }
    };

    if (!stateIsReady) {
      restoreState();
    }
  }, [stateIsReady]);

  const { isFeaturePermissioned: isTouchPointsFeaturePermissioned } = useFeaturePermission('dev');
  const { isFeaturePermissioned: isClaimsFeaturePermissioned } = useFeaturePermission('qa');

  /**
   * This object is used to control the availability of certain features in the application based on their corresponding feature flags.
   * Example:
   * - `TouchPointsTab`: only the permissioned group will have access to it. The generateNavigator function will check if this key
   * matches the tab to be built, and if it does, check against the permission. If no permission is found, the tab will not be built.
   */
  const featureFlagsConfig: Partial<Record<TabScreenName, boolean>> = useMemo(
    () => ({
      TouchPointsTab: isTouchPointsFeaturePermissioned,
      RewardsTab: session?.merchantAdmin?.enableEcoRewards,
      ClaimsTab: isClaimsFeaturePermissioned
    }),
    [isTouchPointsFeaturePermissioned, isClaimsFeaturePermissioned, session?.merchantAdmin?.enableEcoRewards]
  );

  const memoizedLoggedInStackNavigator = useMemo(() => {
    if (!entitlements) return null;

    const LoggedInStack = generateNavigator(navigatorType, entitlements, featureFlagsConfig);

    setStateIsReady(false);

    return (
      <Navigator screenOptions={{ header: () => <CustomHeader />, headerMode: 'screen', cardStyle }}>
        <Group>
          <Screen name="Tabs">{LoggedInStack}</Screen>
        </Group>
      </Navigator>
    );
  }, [entitlements, Group, Navigator, Screen, navigatorType, featureFlagsConfig, setStateIsReady]);

  return stateIsReady && sessionIsReady ? (
    <NavigationContainer
      theme={navTheme}
      ref={navigationRef}
      documentTitle={{
        formatter: (options, route) => {
          return `EcoCart | ${pascalToSpaced((options?.title || route?.name || '').replace('Tab', '')).replace('Eco Cart', 'EcoCart')}`;
        }
      }}
      linking={LinkingConfiguration as LinkingOptions<ReactNavigation.RootParamList>}
      initialState={initialState}
      onStateChange={async (state) => {
        if (state?.routes?.[0].state?.index != undefined) {
          await storage.setItem(StorageKey.nav_state, JSON.stringify(state));
        }
      }}
    >
      {session && session.user.userType === 'merchant_admin' && (
        <ProductFruits
          workspaceCode="hFFqqpqjUDhSUiUh"
          language="en"
          lifeCycle="unmount"
          user={{
            username: session.user.id, // REQUIRED - any unique user identifier
            email: session.user.id,
            firstname: session.user.firstName,
            lastname: session.user.lastName,
            role: session.user.userType
          }}
        />
      )}
      {!isLoggedIn ? <PublicStackNavigator /> : memoizedLoggedInStackNavigator}
    </NavigationContainer>
  ) : (
    <EcoLoadingScreen />
  );
};
