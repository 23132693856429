import { EcoAlert, EcoButton, EcoIcon, EcoText, EcoToggle } from '@components/shared';
import { MerchantAdmin, PayorMode } from '@ecocart/entities';
import { useContactSupport } from '@hooks/useContactSupport';
import { useGlobal } from '@hooks/useGlobal';
import { useOverlay } from '@hooks/useOverlay';
import { updateMerchantAdmin } from '@utils/api/merchant';
import { sendMerchantAdminUpdateMessage } from '@utils/api/slack';
import { PRODUCT_FRUITS_SURVEY_ID } from '@utils/constants/config';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { FontSize } from '@utils/tailwind';
import { useState } from 'react';
import { View } from 'react-native';

interface Props extends GlobalFormProps {
  isDisabled?: boolean;
  merchantAdmin: MerchantAdmin;
  title?: string;
  subTitle?: string;
  titleSize?: FontSize;
  subTitleSize?: FontSize;
}

export function EnableStoreWidgetsToggleForm({ isDisabled, merchantAdmin, onSuccess }: Props): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { session } = useGlobal();
  const { hidePopover, showModal, showToast, hideModal } = useOverlay();
  const { showContactSupportModal } = useContactSupport();
  const _isDisabled = isDisabled || isSubmitting;

  const payorModesWithDisableNotifications: PayorMode[] = ['merchant_paying', 'customer_matching'];

  const onSubmit = async (ecocartEnabled: boolean) => {
    setIsSubmitting(true);

    try {
      await updateMerchantAdmin(merchantAdmin.shopName, {
        ecocartEnabled
      }).then((merchantAdmin) => {
        // launch product fruits survey when widget visibility is toggled off
        if (!ecocartEnabled) {
          window.productFruits?.api?.surveys.startSurvey(PRODUCT_FRUITS_SURVEY_ID);
        }
        onSuccess?.(merchantAdmin);

        if (ecocartEnabled) {
          hidePopover('billing_added');
          showToast({
            content: (
              <EcoAlert
                variant="success"
                closeable={false}
                subtitle="EcoCart is live on your store. Time to start saving the planet, one order at a time."
                style={{ border: 'none' }}
              />
            ),
            type: 'success'
          });
        } else if (payorModesWithDisableNotifications.includes(merchantAdmin.payorMode)) {
          showModal({
            content: (
              <View className="items-cente bg-white p-5 rounded-md max-w-[400px] text-center" style={Gap()}>
                <EcoIcon className="w-full text-success-500" name="check_circle" size="6xl" />
                <EcoText className="w-full" textAlign="center" fontSize="lg" fontWeight="semibold">
                  Your Widgets Have Been Hidden
                </EcoText>
                <EcoText className="w-full" textAlign="center">
                  Due to your payor mode, EcoCart will continue to calculate & charge for each order's offset behind the scenes. Please
                  contact support if you wish to pause EcoCart.
                </EcoText>
                <View className="flex-row w-full" style={Gap()}>
                  <EcoButton
                    className="flex-1"
                    nativeID="hide-widgets-payor-mode-notice__contact-button"
                    fullWidth={true}
                    size="sm"
                    onPress={() => {
                      showContactSupportModal({
                        support_form_submission: `I've hidden my widgets from my store ${session?.merchantAdmin?.shopName} and would like to pause EcoCart service altogether.`
                      });
                    }}
                    variant="ghost"
                  >
                    Contact Support
                  </EcoButton>
                  <EcoButton
                    className="flex-1"
                    nativeID="hide-widgets-payor-mode-notice__dismiss-button"
                    fullWidth={true}
                    size="sm"
                    onPress={hideModal}
                    colorScheme="default"
                  >
                    Dismiss
                  </EcoButton>
                </View>
              </View>
            ),
            config: {
              nativeID: 'hide-widgets-payor-mode-notice'
            }
          });
        }
      });

      setIsSubmitting(false);

      await sendMerchantAdminUpdateMessage('widgets_enabled_change', session, {
        merchantAdmin: { ecocartEnabled },
        dbMerchantAdmin: merchantAdmin
      });
    } catch (error: any) {
      setIsSubmitting(false);
    }
  };

  return (
    <EcoToggle nativeID="settings__widget-toggle" disabled={_isDisabled} value={merchantAdmin.ecocartEnabled} onValueChange={onSubmit} />
  );
}
