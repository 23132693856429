import { useGlobal } from './useGlobal';

const ROLES = ['internal', 'dev', 'qa'] as const;
type Role = (typeof ROLES)[number];

const allDev = ['elvis@ecocart.io', 'elvis+test@ecocart.io', 'nabin@ecocart.io', 'oktavianus@ecocart.io'];
const allQA = [...allDev, 'boney@ecocart.io', 'boney+merchant@ecocart.io', 'addisonlynch@gmail.com', 'addison@ecocart.io'];
const csTeam = ['jennifer@ecocart.io', 'jennifer+merchant@ecocart.io', 'marina@ecocart.io', 'marina+merchant@ecocart.io'];

const internalTeam = [...allDev, ...csTeam, ...allQA];

const roleToListMapping: Record<Role, string[]> = {
  dev: allDev,
  qa: allQA,
  internal: internalTeam
};

export const useFeaturePermission = (role: Role = 'internal'): { isFeaturePermissioned: boolean } => {
  const { session } = useGlobal();

  return { isFeaturePermissioned: roleToListMapping[role].includes(session?.user?.id || '') ?? false };
};
