import { EcoText } from '@components/shared';
import { StatusBeaconType } from '@components/shared/EcoStatusBeacon';
import { TooltipProps } from '@components/shared/EcoTooltip';
import { MerchantAdmin } from '@ecocart/entities';
import { useNavigation } from '@react-navigation/native';
import { Gap } from '@utils/layout';
import { ReactNode } from 'react';
import { Pressable, View } from 'react-native';
import { Statuses } from '../context/global';
interface Props {
  merchantAdmin?: MerchantAdmin;
  statuses: Statuses;
}

interface ReturnType {
  getHeaderBeaconConfig: (type: TooltipKey) => { type: StatusBeaconType; text: string };
  getHeaderTooltipConfig: (type: TooltipKey) => Omit<TooltipProps, 'children'>;
  getFormTooltipConfig: (type: TooltipKey) => Omit<TooltipProps, 'children'>;
}

export type TooltipKey = 'store_processing' | 'ecocart_active' | 'widgets_visible';

const Tooltip = ({ text }: { text: ReactNode }): JSX.Element => {
  return (
    <View style={Gap()}>
      <EcoText fontSize="sm" color="enabled-inverted">
        {text}
      </EcoText>
    </View>
  );
};

export const useMerchantStatusConfigs = ({ merchantAdmin, statuses }: Props): ReturnType => {
  const navigation = useNavigation();
  const BillingLink = (): JSX.Element => {
    return (
      <Pressable
        onPress={() => {
          navigation.navigate('BillingTab');
        }}
      >
        <EcoText fontSize="sm" fontWeight="semibold" color="enabled-inverted">
          {' Billing '}
        </EcoText>
      </Pressable>
    );
  };

  const SettingsLink = (): JSX.Element => {
    return (
      <Pressable
        onPress={() => {
          navigation.navigate('SettingsTab', { screen: 'SettingsEcoCart' });
        }}
      >
        <EcoText fontSize="sm" fontWeight="semibold" color="enabled-inverted">
          {' Settings '}
        </EcoText>
      </Pressable>
    );
  };

  const tooltipText = {
    billingIsEnabled: 'A supported payment method has been set up. You can enable EcoCart.',
    addSupportedPaymentMethodWithBillingLink: (
      <>
        Enable EcoCart by adding a supported payment method to fund your projects. Manage your payment methods in
        <BillingLink />
      </>
    ),
    analysisInProgress: 'Your Store Analysis is still in progress. Sit tight! This may take up to 20 minutes.',
    widgetAreAlwaysHidden: 'Widgets are always hidden when EcoCart is Disabled',
    goToSettingsToEnableWidgets: (
      <>
        Go to <SettingsLink /> to enable widgets
      </>
    ),
    ecocartCanBeEnabled: (
      <>
        EcoCart can be enabled in your <SettingsLink />
      </>
    )
  };

  const getHeaderBeaconConfig = (
    type: 'ecocart_active' | 'widgets_visible' | 'store_processing'
  ): { type: StatusBeaconType; text: string } => {
    switch (type) {
      case 'store_processing':
        if (statuses?.areVariantsCreated) {
          return { type: 'active', text: 'Store Analysis Complete' };
        } else {
          return { type: 'info', text: 'Store Analysis in Progress' };
        }
      case 'ecocart_active':
        if (merchantAdmin?.ecocartActive) {
          return { type: 'active', text: 'EcoCart is Active' };
        } else {
          return { type: 'inactive', text: 'EcoCart is Inactive' };
        }
      case 'widgets_visible':
        if (merchantAdmin?.ecocartEnabled) {
          return { type: 'active', text: 'Widgets Are Visible' };
        } else {
          return { type: 'inactive', text: 'Widgets Are Hidden' };
        }

      default:
        return {} as any;
    }
  };

  const getHeaderTooltipConfig = (key: TooltipKey): Omit<TooltipProps, 'children'> => {
    switch (key) {
      case 'store_processing':
        if (!statuses?.areVariantsCreated) {
          return {
            tooltip: <Tooltip text={tooltipText.analysisInProgress} />,
            placement: 'bottom'
          };
        } else {
          return {} as any;
        }
      case 'ecocart_active':
        if (merchantAdmin?.ecocartActive) {
          return {} as any;
        } else if (!statuses?.areVariantsCreated) {
          return {
            tooltip: (
              <Tooltip
                text={statuses?.isBillingEnabled ? tooltipText.analysisInProgress : tooltipText.addSupportedPaymentMethodWithBillingLink}
              />
            ),
            placement: 'bottom'
          };
        } else {
          return {
            tooltip: (
              <Tooltip
                text={statuses?.isBillingEnabled ? tooltipText.ecocartCanBeEnabled : tooltipText.addSupportedPaymentMethodWithBillingLink}
              />
            ),
            placement: 'bottom'
          };
        }
      case 'widgets_visible':
        if (merchantAdmin?.ecocartEnabled) {
          return {} as any;
        } else if (!statuses?.areVariantsCreated) {
          return {
            tooltip: <Tooltip text={tooltipText.analysisInProgress} />,
            placement: 'bottom'
          };
        } else if (!statuses?.isBillingEnabled) {
          return {
            tooltip: <Tooltip text={tooltipText.addSupportedPaymentMethodWithBillingLink} />,
            placement: 'bottom'
          };
        } else {
          return {
            tooltip: <Tooltip text={tooltipText.goToSettingsToEnableWidgets} />,
            placement: 'bottom'
          };
        }
      default:
        return {} as any;
    }
  };

  const getFormTooltipConfig = (key: TooltipKey): Omit<TooltipProps, 'children'> => {
    switch (key) {
      case 'ecocart_active':
        if (merchantAdmin?.ecocartActive) {
          return {} as any;
        } else if (!statuses?.areVariantsCreated) {
          return {
            tooltip: (
              <Tooltip
                text={statuses?.isBillingEnabled ? tooltipText.analysisInProgress : tooltipText.addSupportedPaymentMethodWithBillingLink}
              />
            ),
            placement: 'bottom'
          };
        } else {
          return {
            tooltip: (
              <Tooltip
                text={statuses?.isBillingEnabled ? tooltipText.billingIsEnabled : tooltipText.addSupportedPaymentMethodWithBillingLink}
              />
            ),
            placement: 'bottom'
          };
        }

      case 'widgets_visible':
        if (merchantAdmin?.ecocartEnabled) {
          return {} as any;
        } else if (!statuses?.areVariantsCreated) {
          return {
            tooltip: <Tooltip text={tooltipText.analysisInProgress} />,
            placement: 'bottom'
          };
        } else if (!statuses?.isBillingEnabled) {
          return {
            tooltip: <Tooltip text={tooltipText.addSupportedPaymentMethodWithBillingLink} />,
            placement: 'bottom'
          };
        } else {
          return {
            tooltip: <Tooltip text={tooltipText.widgetAreAlwaysHidden} />,
            placement: 'bottom'
          };
        }

      default:
        return {} as any;
    }
  };

  return { getHeaderBeaconConfig, getHeaderTooltipConfig, getFormTooltipConfig };
};
