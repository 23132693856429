import { EcoHeaderAuthKeys, GLOBAL_PUBLIC_CONFIG, isValidJSONString } from '@ecocart/universal-utils';
import { noop } from 'lodash';
import { isWeb } from '../constants/device';
import { StorageKey, storage } from '../storage';
import { refreshToken } from './auth';

type AvailableAuth = Record<Partial<StorageKey>, string> | undefined;

type Env = 'DEV' | 'UAT' | 'PROD';

export let ENV: Env = 'PROD';

if (isWeb) {
  if (location.host === 'dev-app.ecocart.io') ENV = 'DEV';
  if (location.host === 'uat-app.ecocart.io') ENV = 'UAT';
  if (location.host === 'app.ecocart.io') ENV = 'PROD';
}

export const APP_BASE_URL = GLOBAL_PUBLIC_CONFIG.APP_BASE_URL[ENV];

export const SLIM_API = GLOBAL_PUBLIC_CONFIG.API_BASE_URL[ENV];
export const USER_MGMT_API = GLOBAL_PUBLIC_CONFIG.USER_MGMT_API_BASE_URL[ENV];
export const PROJECTS_API = GLOBAL_PUBLIC_CONFIG.PROJECTS_API_BASE_URL[ENV];
export const PUPPETEER_API = GLOBAL_PUBLIC_CONFIG.PUPPETEER_API_BASE_URL[ENV];
export const SHOPIFY_NODE_API = GLOBAL_PUBLIC_CONFIG.SHOPIFY_NODE_API_BASE_URL[ENV];
export const POWER_TOOLS_NODE_API = GLOBAL_PUBLIC_CONFIG.POWER_TOOLS_API_BASE_URL[ENV];
export const REPORTING_API = GLOBAL_PUBLIC_CONFIG.NODE_REPORTING_API_BASE_URL[ENV];
export const PAYMENTS_API = GLOBAL_PUBLIC_CONFIG.PAYMENT_PROJECT_BASE_URL[ENV];
export const CONTACTS_API = GLOBAL_PUBLIC_CONFIG.CONTACTS_SERVICE_BASE_URL[ENV];
export const CLAIMS_API = GLOBAL_PUBLIC_CONFIG.CLAIMS_SERVICE_BASE_URL[ENV];
export const PAYMENT_PROJECTS_API = GLOBAL_PUBLIC_CONFIG.PAYMENT_PROJECT_BASE_URL[ENV];
export const REWARDS_API = 'https://rewards.ecocart.io';

const RECHARGE_APIS: Record<Env, string> = {
  DEV: 'https://vfrpu9vuz4.execute-api.us-east-1.amazonaws.com',
  UAT: 'https://vfrpu9vuz4.execute-api.us-east-1.amazonaws.com',
  PROD: 'https://0pufsf6y82.execute-api.us-east-1.amazonaws.com'
};
export const RECHARGE_API = RECHARGE_APIS[ENV];

const MANUFACTURING_APIS: Record<Env, string> = {
  DEV: 'https://4mq9zy1wr0.execute-api.us-east-1.amazonaws.com',
  UAT: 'https://xj1uz0wpp9.execute-api.us-east-1.amazonaws.com',
  PROD: 'https://el2dl3qet4.execute-api.us-east-1.amazonaws.com'
};
export const MANUFACTURING_API = MANUFACTURING_APIS[ENV];

const SPIDER_APIS: Record<Env, string> = {
  DEV: 'https://nx9mo002ci.execute-api.us-east-1.amazonaws.com/prod',
  UAT: 'https://wkauob1l7k.execute-api.us-east-1.amazonaws.com/prod',
  PROD: 'https://g3ny9ksy53.execute-api.us-east-1.amazonaws.com/prod'
};
export const SPIDER_API = SPIDER_APIS[ENV];

export const PRODUCT_MANAGER_APIS: Record<Env, string> = {
  DEV: 'https://fulvt6rd5l.execute-api.us-east-2.amazonaws.com/prod',
  UAT: 'https://l0t7gkxc03.execute-api.us-east-2.amazonaws.com/prod',
  PROD: 'https://9g4f50fp7c.execute-api.us-east-1.amazonaws.com/prod'
};
export const PRODUCT_MANAGER_API = PRODUCT_MANAGER_APIS[ENV];

// prod-only
export const BIN_PACK_API = ENV === 'PROD' ? 'https://38g9u9s87a.execute-api.us-east-1.amazonaws.com/api' : `${SLIM_API}/403`;
export const HUBSPOT_API = ENV === 'PROD' ? 'https://ru4ml8w5l4.execute-api.us-east-1.amazonaws.com/prod' : `${SLIM_API}/403`;

// TODO: TEMPORARILY, BELONGS IN "CONFIG" PACKAGE!!!!
const COGNITO_CONFIG: Record<'DEV' | 'UAT' | 'PROD', { region: string; userPoolId: string; clientId: string }> = {
  DEV: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_dOfBnaUp5',
    clientId: '473qsb8edhlba5s4ovj8vmlli1'
  },
  UAT: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_3Yz5r0yBi',
    clientId: '3rdgnuah7hpiph6q0b6lb78n5b'
  },
  PROD: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_Uo2kxWunB',
    clientId: '13c0hvnavj985rm7sdmn5r4et8'
  }
};

export const COGNITO = COGNITO_CONFIG[ENV];

export const getAvailableAuth = async (): Promise<AvailableAuth> => {
  const cognito_jwt = await storage.getItem(StorageKey.cognito_jwt);
  if (cognito_jwt) return { [StorageKey.cognito_jwt]: cognito_jwt } as any;
  const shpat = await storage.getItem(StorageKey.shpat);
  if (shpat) return { [StorageKey.shpat]: shpat } as any;
  return undefined;
};

const prefetch = async (): Promise<AvailableAuth | null> => {
  try {
    const auth = await getAvailableAuth();
    if (!auth) {
      return auth;
    } else if (auth?.shpat) {
      return auth;
    } else {
      const { cognitoJWT } = await refreshToken();
      if (cognitoJWT) {
        return auth;
      } else {
        storage.setItem(StorageKey.login_redirect, location.href);
        throwHttpError('Unauthorized', 401);
        return null;
      }
    }
  } catch (error: any) {
    storage.setItem(StorageKey.login_redirect, location.href);
    throwHttpError('Unauthorized', 401);
    return null;
  }
};
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export class HttpError extends Error {
  public statusCode: number = 200;

  constructor(message: string, statusCode: number = 400) {
    super(message);
    this.statusCode = statusCode;
  }
}

const buildUrl = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://') ? url : `${SLIM_API}${url}`;
};

export const getHeaders = async (headers: HeadersInit = {}): Promise<HeadersInit> => {
  const auth = await getAvailableAuth();
  const _headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  } as Record<EcoHeaderAuthKeys | 'Accept' | 'Content-Type', string>;

  if (auth?.[StorageKey.cognito_jwt]) _headers['X-Eco-JWT'] = auth[StorageKey.cognito_jwt];
  if (auth?.[StorageKey.shpat]) _headers['X-Eco-Shopify-Access-Token'] = auth[StorageKey.shpat];

  const allHeaders = {
    ..._headers,
    ...headers
  } as Record<string, string>;

  const cleanHeaders = Object.keys(allHeaders).reduce((acc, key) => {
    if (allHeaders[key]) acc[key] = allHeaders[key];
    return acc;
  }, {} as Record<string, string>);

  return cleanHeaders;
};

const sanitizeBody = (data: any) => {
  if (!data) return null;
  else if (data instanceof FormData || typeof data === 'string') return data;
  else return JSON.stringify(data);
};

const apiGet = async (url: string, headers?: HeadersInit): Promise<any> => {
  const method: HttpMethod = 'GET';

  return fetch(buildUrl(url), {
    method,
    headers: await getHeaders(headers)
  })
    .then(handleErrors)
    .then(handleResponse);
};

const apiPost = async (url: string, data: unknown, headers?: HeadersInit): Promise<any> => {
  const method: HttpMethod = 'POST';

  return fetch(buildUrl(url), {
    method,
    headers: data instanceof FormData ? {} : await getHeaders(headers),
    body: sanitizeBody(data)
  })
    .then(handleErrors)
    .then(handleResponse);
};

const apiPut = async (url: string, data?: unknown, headers?: HeadersInit): Promise<any> => {
  const method: HttpMethod = 'PUT';

  return fetch(buildUrl(url), {
    method,
    headers: await getHeaders(headers),
    body: sanitizeBody(data)
  })
    .then(handleErrors)
    .then(handleResponse);
};

const apiDelete = async (url: string, data?: unknown, headers?: HeadersInit): Promise<any> => {
  const method: HttpMethod = 'DELETE';

  return fetch(buildUrl(url), {
    method,
    headers: await getHeaders(headers),
    body: sanitizeBody(data)
  })
    .then(handleErrors)
    .then(handleResponse);
};

export const apiBareCall = async (method: HttpMethod, url: string, data?: unknown, headers?: HeadersInit): Promise<any> => {
  return fetch(buildUrl(url), {
    method,
    headers: await getHeaders(headers),
    body: data ? sanitizeBody(data) : null
  }).then((res) => res.json());
};

const callMap: Record<HttpMethod, (url: string, data: any, headers: any) => Promise<any>> = {
  GET: (url, _, headers) => apiGet(url, headers),
  POST: (url, data, headers) => apiPost(url, data, headers),
  PUT: (url, data, headers) => apiPut(url, data, headers),
  DELETE: (url, data, headers) => apiDelete(url, data, headers)
};

export const apiCall = <T>(method: HttpMethod, url: string, data?: unknown, headers?: HeadersInit): Promise<T> => {
  // ** the strategy here is pre-fetch/check to see if token is valid
  // ** if invalid, initiate auth token refresh, then make call
  return prefetch().then(() => callMap[method](url, data, headers));
};

const handleErrors = async (response: Response) => {
  if (response.ok) {
    return response;
  } else {
    const responseBody = await response.json();
    const message: string = responseBody?.message || responseBody || 'Request failed, try again?';
    throwHttpError(message, response.status);
  }
};

export const throwHttpError = (message: string = 'Request failed, try again?', statusCode: number = 400): void => {
  const error = new HttpError(isValidJSONString(message) ? message : JSON.stringify(message), statusCode);
  throw error;
};

const handleResponse = (response: Response | undefined) => {
  if (!response) return noop;

  const contentType = response?.headers.get('Content-Type') ?? '';

  if (contentType.includes('json')) {
    return response.json();
  } else if (contentType.includes('text/plain') || contentType.includes('application/javascript')) {
    return response.text();
  } else {
    return noop;
  }
};
