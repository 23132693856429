import { EcoCard, EcoText } from '@components/shared';
import { UserType } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useQuery } from '@tanstack/react-query';
import { PaymentType, getPaymentMethods } from '@utils/api/payments';
import { Gap } from '@utils/layout';
import { GlobalFormProps } from '@utils/prop-types';
import { nativeID } from '@utils/types/nativeID';
import clsx from 'clsx';
import React from 'react';
import { Pressable, View } from 'react-native';

interface Props extends GlobalFormProps {
  shopName: string;
}

export default function SelectPaymentMethodForm({ shopName, onSuccess }: Props): JSX.Element {
  const { session } = useGlobal();
  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ['getPaymentMethods', shopName, 'uncached'],
    queryFn: () => getPaymentMethods(shopName),
    cacheTime: 0
  });

  const paymentMethodItems: {
    label: string;
    value: PaymentType;
    description: string;
    disabled: boolean;
    permissions: UserType[];
    nativeId: nativeID;
  }[] = [
    {
      label: 'Add a New Bank Account',
      value: 'bank_account',
      description: 'Make payments directly from your US Bank Account',
      disabled: false,
      permissions: ['ecocart_admin', 'merchant_admin', 'api_admin'],
      nativeId: 'Initiate_new_payment_method--bank_account'
    },
    {
      label: 'Add a New Credit Card',
      value: 'credit_card',
      description: 'EcoCart accepts all major credit cards',
      disabled: false,
      permissions: ['ecocart_admin', 'merchant_admin', 'api_admin'],
      nativeId: 'Initiate_new_payment_method--credit_card'
    },
    {
      label: 'Pay With Shopify',
      value: 'shopify_charge',
      description: 'Sync directly with Shopify Billing to manage payments',
      disabled: isLoading || Object.keys(paymentMethods?.shopify_charge || {}).length > 0,
      permissions: ['ecocart_admin', 'merchant_admin'],
      nativeId: 'Initiate_new_payment_method--shopify_pay'
    }
  ];

  return (
    <EcoCard className="p-6">
      <View className="pb-4 items-center" style={Gap(2)}>
        <EcoText fontSize="2xl" fontWeight="semibold">
          Add Payment Method
        </EcoText>
        <EcoText fontSize="sm">Select which payment type you would like to add</EcoText>
      </View>
      <View className={'flex-col lg:flex-row'} style={Gap()}>
        {paymentMethodItems
          .filter((items) => items.permissions.includes(session?.user.userType as UserType))
          .map(({ value, label, description, disabled, nativeId }) => (
            <Pressable
              key={label}
              disabled={disabled}
              nativeID={nativeId}
              className={clsx(
                disabled && 'opacity-50',
                'flex-1 p-6 border-[1px] border-gray-500 rounded-sm active:border-secondary-400 active:bg-secondary-50'
              )}
              onPress={() => onSuccess && onSuccess(value)}
            >
              <EcoText fontWeight="semibold">{label}</EcoText>
              <EcoText fontSize="xs" color="subdued">
                {description}
              </EcoText>
            </Pressable>
          ))}
      </View>
    </EcoCard>
  );
}
