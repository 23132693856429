import { EcoMap, EcoText } from '@components/shared';
import { MerchantAdmin, Project } from '@ecocart/entities';
import { useGlobal } from '@hooks/useGlobal';
import { useWindow } from '@hooks/useWindow';
import { useNavigation } from '@react-navigation/native';
import { MarkerConfig } from '@screens/ProjectsScreen';
import { useQuery } from '@tanstack/react-query';
import { getProjects } from '@utils/api/projects';
import { Gap } from '@utils/layout';
import { GlobalStylesProps } from '@utils/prop-types';
import { ScrollView, View } from 'react-native';
import { twMerge } from 'tailwind-merge';
import { EcoPageTitle } from '../shared/EcoPageTitle';
import ProjectCard from './ProjectCard';

const loaderProjects = Array(10)
  .fill(0)
  .map(() => new Project());

export const MAP_WIDTH = 350;

interface ProjectListProp extends GlobalStylesProps {
  // If EcoAdmin, it will pass this prop
  merchantAdmin?: MerchantAdmin;
}

export function ProjectList({ merchantAdmin, style }: ProjectListProp): JSX.Element {
  const { session } = useGlobal();
  const { data: projects = [] } = useQuery(['projects'], getProjects);
  const activeProjects = projects.filter((project) => project.active);
  const { isDesktopWeb, height } = useWindow();
  const navigation = useNavigation();
  const isEcoCartAdmin = session?.user.userType === 'ecocart_admin';
  const shopName = merchantAdmin?.shopName;

  const convertProjectToMarkerConfig = (project: Project): MarkerConfig => {
    return {
      title: project.name,
      description: project.description,
      img: project.images?.[0] || '',
      onPress: () => navigation.navigate('Project', { id: project.id }),
      coordinates: project.coordinates
    };
  };

  const selectedProjectName = isEcoCartAdmin ? merchantAdmin?.project?.name : session?.merchantAdmin?.project.name;
  const getSelected = (project: Project) => project.name === selectedProjectName;

  return (
    <View className={twMerge('flex-1 max-w-[1440px] w-full flex mx-auto')} style={style}>
      <ScrollView className="flex-1 px-2 p-4 sm:p-7" style={{ marginRight: isDesktopWeb && !isEcoCartAdmin ? MAP_WIDTH : 0, flex: 1 }}>
        <EcoPageTitle title="Project Selection" subTitle="Select high-quality EcoCart projects - work to combat climate change." />

        <View style={Gap()}>
          <EcoText fontSize="2xl" fontWeight="semibold" className="mt-8">
            Current Project
          </EcoText>
          <View nativeID="current-project">
            <ProjectCard
              shopName={shopName}
              key={(projects || loaderProjects).find(getSelected)?.id}
              project={(projects || loaderProjects).find(getSelected) as Project}
              isSelected
            />
          </View>
        </View>

        <View style={Gap()}>
          <EcoText fontSize="2xl" fontWeight="semibold" className="mt-8">
            Project List
          </EcoText>
          <View nativeID="project-list" style={Gap(4)}>
            {activeProjects?.length > 0
              ? activeProjects
                  .filter((project) => !getSelected(project))
                  .map((project) => <ProjectCard shopName={shopName} key={project.name} project={project} />)
              : loaderProjects.map((project, i) => (
                  <ProjectCard
                    shopName={shopName}
                    isSelected={selectedProjectName === project?.name}
                    key={`loaderProj-${i}`}
                    project={project}
                  />
                ))}
          </View>
        </View>
      </ScrollView>
      {isDesktopWeb && !isEcoCartAdmin ? (
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, width: MAP_WIDTH }}>
          <EcoMap
            center={(activeProjects || []).find(getSelected)?.coordinates}
            width={MAP_WIDTH}
            height={height}
            markers={(activeProjects || []).map(convertProjectToMarkerConfig)}
          />
        </View>
      ) : null}
    </View>
  );
}
