import { Entitlements } from '@context/global';
import { Permission, UserType } from '@ecocart/entities';
import BillingScreen from '@screens/BillingScreen';
import CampaignsScreen from '@screens/CampaignsScreen';
import ClaimsHomeScreen from '@screens/ClaimsHomeScreen';
import ClaimsScreen from '@screens/ClaimsScreen';
import DesignSystemScreen from '@screens/DesignSystemScreen';
import EmailUnsubscribeScreen from '@screens/EmailUnsubscribeScreen';
import ForgotPasswordScreen from '@screens/ForgotPasswordScreen';
import HomeScreen from '@screens/HomeScreen';
import IntegrationsScreen from '@screens/IntegrationsScreen';
import JobStatusScreen from '@screens/JobStatusScreen';
import LoginScreen from '@screens/LoginScreen';
import ManageProjectScreen from '@screens/ManageProjectScreen';
import ManageProjectsScreen from '@screens/ManageProjectsScreen';
import MerchantBillingScreen from '@screens/MerchantBillingScreen';
import MerchantCampaignsScreen from '@screens/MerchantCampaignsScreen';
import MerchantCashbackSettingScreen from '@screens/MerchantCashbackSettingScreen';
import MerchantDashboardScreen from '@screens/MerchantDashboardScreen';
import MerchantOffsettingScreen from '@screens/MerchantOffsettingScreen';
import MerchantProjectsScreen from '@screens/MerchantProjectsScreen';
import MerchantProtectionScreen from '@screens/MerchantProtectionScreen';
import MerchantRewardsHistoryScreen from '@screens/MerchantRewardsHistoryScreen';
import MerchantRewardsScreen from '@screens/MerchantRewardsScreen';
import MerchantSettingsScreen from '@screens/MerchantSettingsScreen';
import MerchantSetupScreen from '@screens/MerchantSetupScreen';
import MerchantUsersScreen from '@screens/MerchantUsersScreen';
import MerchantWidgetsScreen from '@screens/MerchantWidgetsScreen';
import MerchantsScreen from '@screens/MerchantsScreen';
import MyProfileScreen from '@screens/MyProfileScreen';
import NewMemberScreen from '@screens/NewMemberScreen';
import ProjectScreen from '@screens/ProjectScreen';
import ProjectsScreen from '@screens/ProjectsScreen';
import ResetPasswordScreen from '@screens/ResetPasswordScreen';
import RewardsHistoryScreen from '@screens/RewardsHistoryScreen';
import RewardsScreen from '@screens/RewardsScreen';
import SettingsApiKeyScreen from '@screens/SettingsApiKeyScreen';
import SettingsEcoCartScreen from '@screens/SettingsEcoCart';
import SettingsStoreScreen from '@screens/SettingsStore';
import SettingsUserScreen from '@screens/SettingsUserScreen';
import ShopifyScreen from '@screens/ShopifyScreen';
import TouchPointsCartPageScreen from '@screens/TouchPointsCartPage';
import TouchPointsCheckoutPageScreen from '@screens/TouchPointsCheckoutPage';
import TouchPointsHomepageScreen from '@screens/TouchPointsHomepageScreen';
import TouchPointsOtherPagesScreen from '@screens/TouchPointsOtherPages';
import TouchPointsPostPurchasePageScreen from '@screens/TouchPointsPostPurchasePage';
import TouchPointsProductPageScreen from '@screens/TouchPointsProductPage';
import TouchPointsSlideOutCartScreen from '@screens/TouchPointsSlideOutCartPage';
import TranslationsScreen from '@screens/TranslationsScreen';
import UserScreen from '@screens/UserScreen';
import UsersScreen from '@screens/UsersScreen';
import WelcomeScreen from '@screens/WelcomeScreen';
import { SIDE_NAV_WIDTH, TOP_NAV_HEIGHT } from '@utils/constants/navigation';
import { colors } from '@utils/tailwind';
import {
  StackConfig,
  cardStyle,
  generateBottomTabNavigator,
  generateDrawerNavigator,
  generateGroupedStackNavigator,
  generateStackNavigator,
  globalScreenOptions
} from './navigation.utils';

export type ScreenName =
  | TabsName
  | TabScreenName
  | HomeScreenName
  | MerchantsScreenName
  | UsersScreenName
  | ProfileScreenName
  | ManageProjectsScreenName
  | ProjectsScreenName
  | SettingsScreenName
  | TouchPointsScreenName
  | RewardsScreenName
  | InternalToolsScreenName
  | PublicScreenName;

export type TabsName = 'Tabs';
export type TabScreenName =
  | 'HomeTab'
  | 'TouchPointsTab'
  | 'MerchantsTab'
  | 'UsersTab'
  | 'ProfileTab'
  | 'ProjectsTab'
  | 'ManageProjectsTab'
  | 'CampaignsTab'
  | 'RewardsTab'
  | 'SettingsTab'
  | 'BillingTab'
  | 'IntegrationsTab'
  | 'InternalToolsTab'
  | 'ClaimsTab';
// | 'JobStatusTab';

export type HomeScreenName = 'Home';
export type MerchantsScreenName =
  | 'Merchants'
  | 'MerchantSettings'
  | 'MerchantDashboard'
  | 'MerchantProjects'
  | 'MerchantRewards'
  | 'MerchantRewardsHistory'
  | 'MerchantBilling'
  | 'MerchantWidgets'
  | 'MerchantWidgetTesting'
  | 'MerchantWidgetTemplates'
  | 'MerchantWidgetEditor'
  | 'MerchantUsers'
  | 'MerchantCampaigns'
  | 'MerchantOffsetting'
  | 'MerchantProtection'
  | 'MerchantSetup'
  | 'MerchantCashbackSetting';
export type SettingsScreenName = 'SettingsUser' | 'SettingsStore' | 'SettingsEcoCart' | 'SettingsApiKey';
export type UsersScreenName = 'Users' | 'User';
export type ProfileScreenName = 'MyProfile';
export type ProjectsScreenName = 'Projects' | 'Project';
export type ManageProjectsScreenName = 'ManageProjects' | 'ManageProject';
export type CampaignsScreenName = 'Campaigns';
export type BillingScreenName = 'Billing';
export type IntegrationsScreenName = 'Integrations';
export type RewardsScreenName = 'Rewards' | 'RewardsHistory';
export type TouchPointsScreenName =
  | 'TouchPointsHomepage'
  | 'TouchPointsProductPage'
  | 'TouchPointsSlideOutCart'
  | 'TouchPointsCartPage'
  | 'TouchPointsCheckoutPage'
  | 'TouchPointsPostPurchasePage'
  | 'TouchPointsOtherPages';
export type InternalToolsScreenName = 'Translations' | 'DesignSystem' | 'WidgetDesigner';
export type EmailScreenName = 'EmailUnsubscribe';
export type ClaimsScreenName = 'Claims' | 'ClaimsHome';

export const HomeStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'Home', component: HomeScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const SettingsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'SettingsEcoCart', component: SettingsEcoCartScreen, options: globalScreenOptions },
      { name: 'SettingsStore', component: SettingsStoreScreen, options: globalScreenOptions },
      { name: 'SettingsUser', component: SettingsUserScreen, options: globalScreenOptions },
      { name: 'SettingsApiKey', component: SettingsApiKeyScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const TouchPointsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'TouchPointsHomepage', component: TouchPointsHomepageScreen, options: globalScreenOptions },
      { name: 'TouchPointsProductPage', component: TouchPointsProductPageScreen, options: globalScreenOptions },
      { name: 'TouchPointsSlideOutCart', component: TouchPointsSlideOutCartScreen, options: globalScreenOptions },
      { name: 'TouchPointsCartPage', component: TouchPointsCartPageScreen, options: globalScreenOptions },
      { name: 'TouchPointsCheckoutPage', component: TouchPointsCheckoutPageScreen, options: globalScreenOptions },
      { name: 'TouchPointsPostPurchasePage', component: TouchPointsPostPurchasePageScreen, options: globalScreenOptions },
      { name: 'TouchPointsOtherPages', component: TouchPointsOtherPagesScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const ClaimsStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'ClaimsHome', component: ClaimsHomeScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const RewardsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'Rewards', component: RewardsScreen, options: globalScreenOptions },
      { name: 'RewardsHistory', component: RewardsHistoryScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const MerchantsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'Merchants', component: MerchantsScreen, options: globalScreenOptions },
      { name: 'MerchantSettings', component: MerchantSettingsScreen, options: globalScreenOptions },
      { name: 'MerchantDashboard', component: MerchantDashboardScreen, options: globalScreenOptions },
      { name: 'MerchantProjects', component: MerchantProjectsScreen, options: globalScreenOptions },
      { name: 'MerchantRewards', component: MerchantRewardsScreen, options: globalScreenOptions },
      { name: 'MerchantRewardsHistory', component: MerchantRewardsHistoryScreen, options: globalScreenOptions },
      { name: 'MerchantBilling', component: MerchantBillingScreen, options: globalScreenOptions },
      { name: 'MerchantWidgets', component: MerchantWidgetsScreen, options: globalScreenOptions },
      { name: 'MerchantWidgetTesting', component: MerchantWidgetsScreen, options: globalScreenOptions },
      { name: 'MerchantWidgetTemplates', component: MerchantWidgetsScreen, options: globalScreenOptions },
      { name: 'MerchantWidgetEditor', component: MerchantWidgetsScreen, options: globalScreenOptions },
      { name: 'MerchantUsers', component: MerchantUsersScreen, options: globalScreenOptions },
      { name: 'MerchantCampaigns', component: MerchantCampaignsScreen, options: globalScreenOptions },
      { name: 'MerchantOffsetting', component: MerchantOffsettingScreen, options: globalScreenOptions },
      { name: 'MerchantProtection', component: MerchantProtectionScreen, options: globalScreenOptions },
      { name: 'MerchantSetup', component: MerchantSetupScreen, options: globalScreenOptions },
      { name: 'MerchantCashbackSetting', component: MerchantCashbackSettingScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const UsersStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'Users', component: UsersScreen, options: globalScreenOptions },
      { name: 'User', component: UserScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const ProfileStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'MyProfile', component: MyProfileScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const BillingStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'Billing', component: BillingScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const IntegrationsStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'Integrations', component: IntegrationsScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const InternalToolsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'Translations', component: TranslationsScreen, options: globalScreenOptions },
      { name: 'DesignSystem', component: DesignSystemScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const ProjectsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'Projects', component: ProjectsScreen, options: globalScreenOptions },
      { name: 'Project', component: ProjectScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle, animationEnabled: true }
  }
]);

export const ManageProjectsStackNavigator = generateGroupedStackNavigator([
  {
    config: [
      { name: 'ManageProjects', component: ManageProjectsScreen, options: globalScreenOptions },
      { name: 'ManageProject', component: ManageProjectScreen, options: globalScreenOptions }
    ],
    screenOptions: { cardStyle }
  }
]);

export const CampaignsStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'Campaigns', component: CampaignsScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  },
  {
    config: [{ name: 'CampaignUnsubscribe', component: EmailUnsubscribeScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

export const JobStatusStackNavigator = generateGroupedStackNavigator([
  {
    config: [{ name: 'JobStatus', component: JobStatusScreen, options: globalScreenOptions }],
    screenOptions: { cardStyle }
  }
]);

// -- CORE NAVIGATION PRINCIPLES --
// 1) *all* screens must live on a stack, as part of a "StackNavigator" in order to be navigable via @react-navigation
//    - e.g. <EcoLink href="/pets/2"> || const navigation = useNavigation(); .. navigation.navigate('/merchants/[shopName]');
// 2) if you want tabs to be visible, new stack config **must** go under one of the existing tabs' StackNavigator
//    - when in doubt, add to 'Home'
// 3) if you want a screen _without_ tabs, create a new StackNavigator above, and add to the NontabbedNavigators array below
//    - consider using a global modal to avoid needing to navigate at all. If it's a one-off, might be better to swipe up/down while staying in place
//    - e.g. const { showModal } = useOverlay();
//           showModal(<ComponentName />)

// _tab1 ---- _tab2 ---- _tabN     ... _rando1 ---- _randoN
//  _screen1   _screen1   _screen1      _screen1     _screen1
//  _screen2   _screen2   _screen2      _screen1     _screen1

// Root Stacks

export type NavigatorType = 'tab' | 'drawer';

const UserStacksConfig: Record<UserType, { name: TabScreenName; requiresPerm?: Permission }[]> = {
  merchant_admin: [
    { name: 'HomeTab' },
    { name: 'ClaimsTab' },
    { name: 'TouchPointsTab' },
    { name: 'SettingsTab' },
    { name: 'RewardsTab' },
    { name: 'BillingTab' },
    { name: 'ProjectsTab' },
    { name: 'IntegrationsTab' }
  ],
  ecocart_admin: [
    { name: 'HomeTab' },
    { name: 'MerchantsTab', requiresPerm: 'get_all_merchants' },
    { name: 'ManageProjectsTab' },
    { name: 'CampaignsTab' },
    { name: 'UsersTab' },
    { name: 'InternalToolsTab' }
  ],
  api_admin: [
    { name: 'HomeTab' },
    { name: 'SettingsTab' },
    { name: 'BillingTab' },
    { name: 'ProjectsTab' }
    // TODO: Add back when Job Status feature ready
    // { name: 'JobStatusTab' }
  ]
};

const TabStacksConfig: Record<TabScreenName, StackConfig> = {
  HomeTab: {
    name: 'HomeTab',
    component: HomeStackNavigator,
    options: { title: 'Home', iconName: 'home', id: 'global-tab-menu__home' }
  },
  MerchantsTab: {
    name: 'MerchantsTab',
    component: MerchantsStackNavigator,
    options: { title: 'Merchants', iconName: 'storefront', id: 'global-tab-menu__merchants' }
  },
  TouchPointsTab: {
    name: 'TouchPointsTab',
    component: TouchPointsStackNavigator,
    options: { title: 'Touch Points', iconName: 'web_traffic', id: 'global-tab-menu__touch-points' }
  },
  ClaimsTab: {
    name: 'ClaimsTab',
    component: ClaimsStackNavigator,
    options: { title: 'Claims', iconName: 'description', id: 'global-tab-menu__claims' }
  },
  SettingsTab: {
    name: 'SettingsTab',
    component: SettingsStackNavigator,
    options: { title: 'Settings', iconName: 'settings', id: 'global-tab-menu__settings' }
  },
  RewardsTab: {
    name: 'RewardsTab',
    component: RewardsStackNavigator,
    options: { title: 'Rewards', iconName: 'trophy', id: 'global-tab-menu__rewards' }
  },
  UsersTab: {
    name: 'UsersTab',
    component: UsersStackNavigator,
    options: { title: 'Users', iconName: 'group', id: 'global-tab-menu__users' }
  },
  ProjectsTab: {
    name: 'ProjectsTab',
    component: ProjectsStackNavigator,
    options: { title: 'Projects', iconName: 'public', id: 'global-tab-menu__projects' }
  },
  // TODO: Add back when Job Status feature ready
  // JobStatusTab: {
  //   name: 'JobStatusTab',
  //   component: JobStatusStackNavigator,
  //   options: { title: 'Job Status', iconName: 'full_stacked_bar_chart', id: 'global-tab-menu__product-catalogue' }
  // },
  ManageProjectsTab: {
    name: 'ManageProjectsTab',
    component: ManageProjectsStackNavigator,
    options: { title: 'Projects', iconName: 'public', id: 'global-tab-menu__manage-projects' }
  },
  CampaignsTab: {
    name: 'CampaignsTab',
    component: CampaignsStackNavigator,
    options: { title: 'Campaigns', iconName: 'mail', id: 'global-tab-menu__campaigns' }
  },
  BillingTab: {
    name: 'BillingTab',
    component: BillingStackNavigator,
    options: { title: 'Billing', iconName: 'credit_card', id: 'global-tab-menu__billing' }
  },
  IntegrationsTab: {
    name: 'IntegrationsTab',
    component: IntegrationsStackNavigator,
    options: { title: 'Integrations', iconName: 'widgets', id: 'global-tab-menu__integrations' }
  },
  ProfileTab: {
    name: 'ProfileTab',
    component: ProfileStackNavigator,
    options: { title: 'Profile', iconName: 'person', id: 'global-tab-menu__profile' }
  },
  InternalToolsTab: {
    name: 'InternalToolsTab',
    component: InternalToolsStackNavigator,
    options: { title: 'Internal Tools', iconName: 'construction', id: 'global-tab-menu__internal-tools' }
  }
};

const DrawerStacksConfig: Record<TabScreenName, StackConfig> = {
  HomeTab: { name: 'HomeTab', component: HomeStackNavigator, options: { title: 'Home', iconName: 'home', id: 'global-side-menu__home' } },
  TouchPointsTab: {
    name: 'TouchPointsTab',
    component: TouchPointsStackNavigator,
    options: { title: 'Touch Points', iconName: 'web_traffic', id: 'global-side-menu__touch-points' }
  },
  ClaimsTab: {
    name: 'ClaimsTab',
    component: ClaimsStackNavigator,
    options: { title: 'Claims', iconName: 'description', id: 'global-side-menu__claims' }
  },
  MerchantsTab: {
    name: 'MerchantsTab',
    component: MerchantsStackNavigator,
    options: { title: 'Merchants', iconName: 'storefront', id: 'global-side-menu__merchants' }
  },
  SettingsTab: {
    name: 'SettingsTab',
    component: SettingsStackNavigator,
    options: { title: 'Settings', iconName: 'settings', id: 'global-side-menu__settings' }
  },
  RewardsTab: {
    name: 'RewardsTab',
    component: RewardsStackNavigator,
    options: { title: 'Rewards', iconName: 'trophy', id: 'global-side-menu__rewards' }
  },
  UsersTab: { name: 'UsersTab', component: UsersStackNavigator, options: { title: 'Users', iconName: 'group' } },
  ProjectsTab: {
    name: 'ProjectsTab',
    component: ProjectsStackNavigator,
    options: { title: 'Projects', iconName: 'public', id: 'global-side-menu__projects' }
  },
  ManageProjectsTab: {
    name: 'ManageProjectsTab',
    component: ManageProjectsStackNavigator,
    options: { title: 'Projects', iconName: 'public', id: 'global-side-menu__manage-projects' }
  },
  CampaignsTab: {
    name: 'CampaignsTab',
    component: CampaignsStackNavigator,
    options: { title: 'Campaigns', iconName: 'mail', id: 'global-side-menu__campaigns' }
  },
  // TODO: Add back when Job Status feature ready
  // JobStatusTab: {
  //   name: 'JobStatusTab',
  //   component: JobStatusStackNavigator,
  //   options: { title: 'Job Status', iconName: 'full_stacked_bar_chart', id: 'global-tab-menu__product-catalogue' }
  // },
  BillingTab: {
    name: 'BillingTab',
    component: BillingStackNavigator,
    options: { title: 'Billing', iconName: 'credit_card', id: 'global-side-menu__billing' }
  },
  IntegrationsTab: {
    name: 'IntegrationsTab',
    component: IntegrationsStackNavigator,
    options: { title: 'Integrations', iconName: 'widgets', id: 'global-side-menu__integrations' }
  },
  ProfileTab: {
    name: 'ProfileTab',
    component: ProfileStackNavigator,
    options: { title: 'Profile', iconName: 'person', id: 'global-side-menu__profile' }
  },
  InternalToolsTab: {
    name: 'InternalToolsTab',
    component: InternalToolsStackNavigator,
    options: { title: 'Internal Tools', iconName: 'construction', id: 'global-side-menu__internal-tools' }
  }
};

function isFeatureFlagDisabled(featureFlagsConfig: Partial<Record<TabScreenName, boolean>>, stackName: TabScreenName) {
  return Object.keys(featureFlagsConfig).includes(stackName) && !featureFlagsConfig[stackName];
}

export const generateNavigator = (
  type: NavigatorType,
  { userType, permissions }: Entitlements,
  featureFlagsConfig: Partial<Record<TabScreenName, boolean>>
): (() => JSX.Element) => {
  if (type === 'tab') {
    const tabs = UserStacksConfig[userType]
      .filter((stack) => {
        const hasRequiredPermission = !stack.requiresPerm || permissions.includes(stack.requiresPerm);
        return !isFeatureFlagDisabled(featureFlagsConfig, stack.name) && hasRequiredPermission;
      })
      .map((stack) => TabStacksConfig[stack.name]);
    return generateBottomTabNavigator(tabs);
  } else if (type === 'drawer') {
    const drawerItems = UserStacksConfig[userType]
      .filter((stack) => {
        const hasRequiredPermission = !stack.requiresPerm || permissions.includes(stack.requiresPerm);
        return !isFeatureFlagDisabled(featureFlagsConfig, stack.name) && hasRequiredPermission;
      })
      .map((stack) => DrawerStacksConfig[stack.name]);
    return generateDrawerNavigator(drawerItems, {
      header: () => null,
      drawerPosition: 'left',
      drawerStyle: {
        padding: 0,
        width: SIDE_NAV_WIDTH,
        borderRightColor: colors.gray[100],
        marginTop: -TOP_NAV_HEIGHT,
        zIndex: 10000
      },
      drawerType: 'permanent'
    });
  } else {
    return { stack: undefined, screenOptions: null } as any;
  }
};

///////////////////////
// PUBLIC ROUTES STACKS //
///////////////////////

export type PublicScreenName =
  | 'Login'
  | 'Welcome'
  | 'NewMember'
  | 'ForgotPassword'
  | 'ResetPassword'
  | 'Shopify'
  | 'EmailUnsubscribe'
  | 'Claims';

export const PublicStackNavigator = generateStackNavigator([
  { name: 'Login', component: LoginScreen, options: { title: 'Login', ...globalScreenOptions } },
  { name: 'Welcome', component: WelcomeScreen, options: { title: 'Welcome', ...globalScreenOptions } },
  { name: 'NewMember', component: NewMemberScreen, options: { title: 'New Member', ...globalScreenOptions } },
  { name: 'ForgotPassword', component: ForgotPasswordScreen, options: { title: 'Forgot Password', ...globalScreenOptions } },
  { name: 'ResetPassword', component: ResetPasswordScreen, options: { title: 'Reset Password', ...globalScreenOptions } },
  { name: 'Shopify', component: ShopifyScreen, options: { title: 'Shopify', ...globalScreenOptions } },
  { name: 'DesignSystem', component: DesignSystemScreen, options: { title: 'EcoCart Design System', ...globalScreenOptions } },
  { name: 'EmailUnsubscribe', component: EmailUnsubscribeScreen, options: { title: 'Email Subscription', ...globalScreenOptions } },
  { name: 'Claims', component: ClaimsScreen, options: { title: 'Claims', ...globalScreenOptions } }
]);
