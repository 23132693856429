import { EcoTab, EcoTabContent, EcoTabs, EcoText } from '@components/shared';
import { DemoContainer } from '../views/DemoContainer';
import { InternalToolsCard } from '../views/InternalToolsCard';

export const Tabs: EcoTab[] = [
  {
    label: 'EcoCart Settings',
    screenName: 'DesignSystem'
  },
  {
    label: 'Store Details',
    screenName: 'DesignSystem'
  },
  {
    label: 'User Settings (Disabled)',
    screenName: 'DesignSystem',
    isDisabled: true
  }
];

export function DSTabs(): JSX.Element {
  return (
    <InternalToolsCard componentName="EcoTabs">
      <DemoContainer className="bg-warning-50">
        <EcoText className="mb-6">
          Below is just for illustration purposes. Clicking the tabs won't do anything because we are not setting it to navigate to a
          different screen. For testing, please just play with any of the tabs within the app.
        </EcoText>
        {/* Actual demo component */}
        <EcoTabs tabs={Tabs} prefix="Demo" activeScreen="DesignSystem">
          <EcoTabContent>
            <EcoText>Some content</EcoText>
          </EcoTabContent>
        </EcoTabs>
        {/* End of actual demo component */}
      </DemoContainer>
    </InternalToolsCard>
  );
}
